<template>
  <div>
    <v-menu
      v-model="shower"
      :position-x="x"
      :position-y="y"
      offset-y
      close-on-content-click
      close-on-click
      transition="slide-y-transition"
    >
      <v-list dense>
        <!-- <v-list-item-group v-model="item" color="primary"> -->
        <!-- <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="goto(item.to)"
          :disabled="permission_denied_account"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
            
              @click="fn_shortcut()"
              
            >
              <v-list-item-icon>
                <v-icon v-text="itemShortcut[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemShortcut[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
        <!-- </v-list-item-group> -->
        <!-- <v-list-item
          v-for="(item, i) in Itemcopyfile"
          :key="i"
          @click="goto(item.to)"
          :disabled="permission_denied_account"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item
          @click="goto('openUploadfolders')"
          :disabled="permission_denied_account"
        >
          <v-list-item-icon>
            <v-icon style="color:#FEC61C;">mdi-folder-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >{{ $t("mainRClick.uploadfolder"),}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="goto('openUploadfiles')"
          :disabled="permission_denied_account"
        >
          <v-list-item-icon>
            <v-icon style="color:#579C51;">mdi-file-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >{{ $t("mainRClick.uploadfile"),}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="goto('openNewfolder')"
          :disabled="permission_denied_account"
        >
          <v-list-item-icon>
            <v-icon style="color:#ffc107;">mdi-folder-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content :close-on-content-click="false">
            <v-list-item-title
              >{{ $t("mainRClick.newfolder"),}}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-menu offset-x bottom open-on-hover :left="fn_checkpositionmenu()" v-if="$route.name !== 'directorysharefile' && this.feature_onlyoffice !== false">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on" :disabled="permission_denied_account">
                    <v-list-item-icon>
                      <v-icon style="color:#579C51;">mdi-file-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("mainRClick.createfile") }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon :disabled="permission_denied_account" medium>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <v-list dense tile>
                  <v-list-item
                    v-for="(item, i) in itemsCreateFile"
                    :key="i"
                    @click="create_files(item.text, item.document_type, item.file_extension)"
                  >
                    <!-- <v-list-item-icon> -->
                      <v-icon class="mr-3" medium v-text="item.icon" :style="item.document_type === 'word' ? 'color:#0263d1;' : item.document_type === 'excel' ? 'color:#00733b;' : item.document_type === 'powerpoint' ? 'color:#C43E1C;' : 'color:blue-grey;'"></v-icon>
                    <!-- </v-list-item-icon> -->
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
        </v-menu>
        <!-- วางไฟล์ -->
        <v-list-item 
          @click="fn_pastefile_"
          v-if="!permission_denied_account && isOpenPaste_file"
        >
          <v-list-item-icon>
            <v-icon>file_copy</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('copyandpaste.file_pasted')}}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-menu>
    <uploadfiles
      :sendswitchapi="sendswitchapi"
      :show="openUploadfiles"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="openUploadfiles = false"
      @openuploadfile="openUploadfiles = true"
      @closemobile="closeDialog(), (openUploadfiles = false), $emit('opendragselect')"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @opendragselect="$emit('opendragselect')"
    ></uploadfiles>
    <!-- @closeDrag="removeEvent()" -->
    <!-- @openDrag="addEvent()" -->
    <createfolder
      :show="openUploadfolder"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="openUploadfolder = false"
      @closeandreload="closeDialog(), $emit('opendragselect')"
      @openuploadfolder="openUploadfolder = true"
      @opendragselect="$emit('opendragselect')"
      @folder_detail="open_folder_when_create_complete"
    ></createfolder>
    <!-- @closeDrag="removeEvent()" -->
    <!-- @openDrag="addEvent()" -->
    <uploadfolder
      :parentfolder="parentfolder"
      :enabled_inputfile="openUploadfolderinput"
      :departmentid="departmentid"
      @closewithoutload="openUploadfolderinput = false"
      @closeUpload="(openUploadfolderinput = false), closeDialog()"
      @reload="$emit('loaddatafrommainright'), $emit('openDrag')"
    ></uploadfolder>
    <shortcut
      :show="openshortcut"
      @closeshortcut="(openshortcut = false), closeDialog()"
    ></shortcut>
    <createfiles
      :show="openCreatefiles"
      :document_text="document_text"
      :document_type="document_type"
      :file_type="file_type"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="openCreatefiles = false, $emit('opendragselect')"
      @closeandreload="closeDialog(), $emit('opendragselect')"
      @openuploadfolder="openCreatefiles = true"
      @opendragselect="$emit('opendragselect')"
      @file_detail="open_file_when_create_complete"
    >
    </createfiles>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";
import VueCookies from "vue-cookies";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";
const createfiles = () => import("../upload/dialog-createfiles");
const createfolder = () => import("../upload/dialog-uploadfolder");
const uploadfiles = () => import("../upload/dialog-uploadfiles2");
const uploadfolder = () => import("../upload/input-uploadfolder");
const shortcut = () => import("../optional/dialog-shortcut");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: [
    "show",
    "AreaX",
    "AreaY",
    "parentfolder",
    "permissionfolder",
    "sendswitchapi",
    "departmentid",
  ],
  components: { createfolder, uploadfiles, uploadfolder, shortcut, createfiles },
  data: function() {
    return {
      isOpenPaste_file: false,
      openshortcut: false,
      listfilecopy: [],
      folderParent: [],
      permission_denied_account: true,
      openUploadfiles: false,
      openUploadfolder: false,
      openUploadfolderinput: false,
      openCreatefiles:false,
      file_type:"",
      document_type:"",
      document_text:"",
      itemShortcut: [
        {
          text: "shortcut_header",
          icon: "mdi-file-link",
          to: "printPDF",
          event: "fn",
        },
      ],
      items: [
        // { text: 'My Files', icon: 'mdi-folder' },
        // { text: 'Shared with me', icon: 'mdi-account-multiple' },
        // { text: 'Starred', icon: 'mdi-star' },
        // { text: 'Recent', icon: 'mdi-history' },
        {
          text: "mainRClick.uploadfile",
          icon: "mdi-file-upload",
          to: "openUploadfiles",
        },
        {
          text: "mainRClick.uploadfolder",
          icon: "mdi-folder-upload",
          to: "openUploadfolders",
        },
        {
          text: "mainRClick.newfolder",
          icon: "mdi-folder-plus",
          to: "openNewfolder",
        },
      ],
      Itemcopyfile: [
        {
          text: "วาง",
          icon: "mdi-file-replace",
          to: "paste",
        },
      ],
      x: 0,
      y: 0,
      itemsCreateFile: [
        {
          text: "Microsoft Word Document",
          icon: "mdi-file-word",
          document_type: "word",
          file_extension: "docx",
        },
        {
          text: "Microsoft Excel Worksheet",
          icon: "mdi-file-excel",
          document_type: "excel",
          file_extension: "xlsx",
        },
        {
          text: "Microsoft PowerPoint Presentation",
          icon: "mdi-file-powerpoint",
          document_type: "powerpoint",
          file_extension: "pptx",
        },
        {
          text: "Text Document",
          icon: "mdi-file-document",
          document_type: "text",
          file_extension: "txt",
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "feature_onlyoffice"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.showmenus();
          this.$emit("closeDrag");
          // Read navigator clipboard (ปืดก่อนชั่วคราว)
          //this.fn_readNavigatorClipboard()
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closemainonly");
          this.$emit("closeDrag");
          if (this.openUploadfiles === false) {
            this.$emit("openDrag");
          }
        }
      },
    },
  },
  watch: {
    show: function(val) {
      // เช็คทั้ง permission_account และ permission_account_v2
      if (this.permissionfolder["upload"] === "True") {
        this.permission_denied_account = false;
      } else if (this.permissionfolder === "03" || this.permissionfolder === "04" || this.permissionfolder === "05") {
        this.permission_denied_account = false;
      } else {
        this.permission_denied_account = true;
      }
      console.log("this.permission_denied_account", this.permission_denied_account);
    },
  },
  methods: {
    open_folder_when_create_complete(data){
      // console.log("open_folder_when_create_complete",data);
      this.$emit("open_folder",data)
    },
    open_file_when_create_complete(data){
      // console.log("open file when create complete",data);
      this.$emit("open_file_office",data)
    },
    async fn_pastefile_ () {
      try{
        console.log("paste files here ", )
        let ReadClipBoard = await navigator.clipboard.readText()
        var JSONParse_ClipArray = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(ReadClipBoard, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, })))
        this.$emit("pasted_file", {
          files_: JSONParse_ClipArray.array_files, 
          to_: {type: "ROOT", id: ""} })
      }
      catch (err) {
        console.log("Error ", err)
        return 
      }
    },
    async fn_readNavigatorClipboard () {
      try{
        this.isOpenPaste_file = false
        let ReadClipBoard =  await navigator.clipboard.readText()
        var JSONParse_ClipArray = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(ReadClipBoard, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }))) 
        if(this.dataAccountActive.type === "Citizen" && (this.$route.path === "/mydrive" || this.$route.name === "directory" )){
          if(JSONParse_ClipArray.taxId === "" && JSONParse_ClipArray.user_id === this.dataUsername){
            this.isOpenPaste_file = true
            return
          } else {
            this.isOpenPaste_file = false
            return
          }
        } 
        else if (this.dataAccountActive.type === "Business" && (this.$route.path === "/mydrive" || this.$route.name === "directory" )) {
          if((JSONParse_ClipArray.taxId === "" || JSONParse_ClipArray.taxId === this.dataAccountActive.taxid) && JSONParse_ClipArray.user_id === this.dataUsername){
            this.isOpenPaste_file = true
          }
        }
      } 
      catch (err) {
        console.log("Error ", err)
        return
      } 
    },
    // จัดตำแหน่ง Submenu ให้ไปอยู่ทางซ้าย
    fn_checkpositionmenu() {
      let listWidth = 250;
      if (this.x + listWidth > window.innerWidth) {
        return true;
      } else {
        return false;
      }
    },
    
    fn_shortcut() {
      this.openshortcut = true;
    },
    goto(parameter) {
      if (parameter === "openUploadfiles") {
        this.openUploadfiles = true;
        this.$emit("closedragselect");
      } else if (parameter === "openNewfolder") {
        this.openUploadfolder = true;
        this.$emit("closedragselect");
        this.$emit("closeDrag");
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
          // console.log("checkinet_jv",checkinet_jv);
          // if(checkinet_jv === -1){
        // let checkinet_jv = VueCookies.get("feature_onlyoffice")
        //   if(checkinet_jv === 'false'){
        //     this.param_preview = "preview";
        //   }else{
        //     if(
        //       // (['04', '05', '06'].includes(file.file_permission_2)) &&
        //       (file.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.file_type.toLowerCase()) || 
        //       file.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.data_type.toLowerCase()))
        //     ){
        //       this.param_preview = "editfileonline";
        //     }else{
        //       this.param_preview = "preview";
        //     }
        //   }
        // if(file.editing_status === 'S'){
        //     Toast.fire({
        //         icon: "warning",
        //         title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
        //       });
        //   }else if(file.editing_status === 'Y'){
        //     if(file.last_update_name.name_th === 'ฉัน'){
        //       this.openUploadfolder = true;
        //     }else{
        //       Toast.fire({
        //         icon: "warning",
        //         title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
        //       });
        //     }
        //   }else{
          // }
        // this.openUploadfolder = true;
        
      } else if (parameter === "openUploadfolders") {
        // เปลี่ยนให้เรียกใช้ components จากหน้าหลัก
        this.$emit('openuploadfolder')
        // this.openUploadfolderinput = true;
        // this.$emit("sendfolderinput", true);
      } else if (parameter === "paste") {
        this.fn_pastefile();
      }
    },
    //วางไฟล์
    async fn_pastefile() {
      this.listfilecopy = [];
      let data_copy = sessionStorage.getItem("filecopy");
      let data_paste = JSON.parse(data_copy);
      console.log("data_paste", data_paste);

      for (let i = 0; i < data_paste.length; i++) {
        this.listfilecopy.push({
          data_type: data_paste[i].file_type === "folder" ? "folder" : "file",
          data_id: data_paste[i].file_id,
        });
      }
      console.log("listfilecopy", this.listfilecopy);

      let payload = {
        account_id: this.dataAccountId,
        folder_id: this.$route.params.id,
        copied: this.listfilecopy,
      };
      console.log("payloadcopyfile", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      let resp = await axios.post(
        process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      console.log(resp);
      try {
        if (resp.data.status === "OK") {
          // this.createprogress = false;
          Toast.fire({
            icon: "success",
            title: "วาง",
          });
          this.$emit("reload");
        } else {
          // this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: resp.data.errorMessage,
          });
        }
      } catch (err) {
        console.log(err);
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    showmenus() {
      this.showMenu = false;
      this.x = this.AreaX;
      this.y = this.AreaY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    closeDialog() {
      this.openUploadfiles = false;
      this.openUploadfolder = false;
      this.openUploadfolderinput = false;
      this.openCreatefiles = false;
      this.$emit("loaddatafrommainright");
      this.$emit("openDrag");
    },
    fn_receivefolderinput(_file) {
      // console.log(_file);
      this.$emit("sendfolderinput", _file);
      this.openUploadfolderinput = false;
      this.$emit("closemainonly");
      this.$emit("openDrag");
    },
    create_files(text, document_type, type){
      this.openCreatefiles = true;
      this.document_text = text;
      this.document_type = document_type;
      this.file_type = type;
      this.$emit("closedragselect")
    },
  },
};
</script>
